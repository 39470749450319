import * as Sentry from "@sentry/remix";
import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import { startTransition, StrictMode, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";

Sentry.init({
  dsn: "https://6fdd2261dab2c03a9dcb1afd57cb9c50@o4507647291293696.ingest.us.sentry.io/4507647291490304",
  tracesSampleRate: 1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
  environment: process.env.NODE_ENV,
  release: process.env.NODE_ENV === 'production' ? process.env.npm_package_version : undefined,

  integrations: [Sentry.browserTracingIntegration({
    useEffect,
    useLocation,
    useMatches
  }), Sentry.replayIntegration({
    maskAllText: false,
    maskAllInputs: false,
    blockAllMedia: false,
  })]
})

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>
  );
});